.get-in-touch {
  font-size: 5rem;
  padding-bottom: 3rem;

  @media only screen and (max-width: 1280px) {
    font-size: 3rem;
  }
}

.email {
  font-size: 3rem;
  color: rgb(189, 189, 189);
  @media only screen and (max-width: 1280px) {
    font-size: 2rem;
  }
}

.contact-info {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 83vh;
  overflow: hidden;
  padding-bottom: 15vh;
  animation: fadein 2s forwards;
  animation-delay: 0.5s;
  @media only screen and (max-width: 1280px) {
    height: 80vh;
    padding-bottom: 0;
  }
}


.social-links {
  opacity: 0;
  animation: fadein 2s forwards;
  animation-delay: 1.5s;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  font-size: 4rem;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}