#about {
  @media only screen and (max-width: 1280px) {
    padding-top: 5vh;
  }
}

.about-description {
  font-size: 2rem;

  @media only screen and (max-width: 1280px) {
    font-size: 1.75rem;
    text-align: center;
  }
}
