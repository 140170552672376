body[data-theme="light"] {
  @import '../shared-colors';

  html {
    color: $primary-bg-dark;
    background-color: $primary-bg-light;
  }

  .divider, h1, h2, h3, .get-in-touch, .project-link-button, .title-styles, .icon-container, .nav-bar, .link-href, .project-header {
    color: $primary-bg-dark;
  }

  .project-date, .project-section {
    background-color: transparent;
    color: $primary-bg-dark;
  }

  .background, .center {
    background-color: $primary-bg-light;
  }

  .nav-bar {
    background-color: hsla(0, 22%, 10%, 10%);
  }

  .nav-link {
    color: $primary-bg-dark;

    &:hover {
      color: transparentize($primary-bg-dark, 0.5);
    }
  }

  .link-text {
    &::after {
      background-color: $primary-bg-light;
    }
  }

  .view-button {
    border: 2px solid red;

    .arrow {
      color: red;
    }

    a {
      color: $primary-bg-dark;
    }
  }

  .name {
    color: red;
  }



  footer {
    background-color: $primary-bg-light;
    color: $primary-bg-dark;
  }

  .nav-button {
    background-color: transparent;
  }

  a {
    color: $primary-bg-dark;

    &:hover {
      p {
        text-shadow: 0 0 2px rgba(21, 13, 13, 0.6);
      }
    }
  }

  .skills-tile {
    background-color: transparentize($primary-bg-dark, 0.92);
  }
}