@import './scss/app';
// Base file

@import './scss/about';
@import './scss/footer';
@import './scss/hero';
@import './scss/nav';
@import "./scss/projects";
@import './scss/skills';
@import "./scss/shared-colors";
@import "./scss/contact";

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}




