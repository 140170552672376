.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hero-text-container {
  display: flex;
  width: 50vw;
  height: 20vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .hero-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    position: relative;
  }

  @media only screen and (max-width: 1280px) {
    width: 80vw;
    height: 30vh;
  }
}

.hero-one {
  font-size: 4.5rem;
  animation: fadein 1s, fadedown 1s forwards;
  margin-bottom: 0;

  @media only screen and (max-width: 1280px) {
    font-size: 3rem;
  }
}

.hero-two {
  font-size: 3.5rem;
  opacity: 0;
  animation: fadein 1s forwards, fadedown 1s forwards;
  animation-delay: 1s;

  @media only screen and (max-width: 1280px) {
    font-size: 2.5rem;
  }
}

.hero-three {
  font-size: 2.5rem;
  opacity: 0;
  animation: fadein 1s forwards, fadedown 1s forwards;
  animation-delay: 2s;

  @media only screen and (max-width: 1280px) {
    font-size: 2rem;
  }
}

.view-button {
  opacity: 0;
  animation: fadein 2s forwards;
  animation-delay: 3s;
  border-radius: 8px;
  padding: 8px;
  width: auto;
  font-size: 2rem;
  background: linear-gradient(to left, transparent 50%, red 50%) right;
  background-size: 200%;
  transition: background-position .2s ease-out;
  margin-top: 2rem;

  a {
    text-decoration: none;
    display: flex;
    gap: 0.7rem;
    align-items: center;
    transition: transform .2s;
  }

  .arrow {
    margin-top: 0.5rem;
    margin-right: 0.2rem;
  }

  &:hover {
    background-position: left;

    a {
      transform: translateX(1.3rem);
    }
  }

  @media only screen and (max-width: 1280px) {
    font-size: 24px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadedown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}