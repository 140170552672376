body[data-theme="dark"] {

  @import "../shared-colors";

  html {
    background-color: black;
  }

  .divider, h1, h2, h3, .get-in-touch, .project-link-button, .title-styles, .icon-container, .nav-bar, .link-href, .project-header, p, span, i {
    color: white;
  }

  .project-date, .project-section {
    background-color: transparent;
    color: $primary-bg-light;
  }

  .background, .center {
    background-color: $primary-bg-dark;
  }

  .nav-bar {
    background-color: hsla(0, 22%, 10%, 30%);
  }

  .nav-link {
    color: rgba(238, 240, 235, 0.60);

    &:hover {
      color: $primary-bg-light;
    }
  }

  .link-text {
    &::after {
      background-color: $primary-bg-light;
    }
  }

  .view-button {
    border: 2px solid $tertiary-dark;

    .arrow {
      color: red;
    }

    a {
      color: $primary-bg-light;
    }
  }

  .name {
    color:red;
  }

  footer {
    background-color: $primary-bg-dark;
    color: $primary-bg-light;
  }

  .nav-button {
    background-color: transparent;
  }

  a {
    color: $primary-bg-dark;

    &:hover {
      p {
        text-shadow: 0 0 2px rgba(21, 13, 13, 0.6);
      }
    }
  }

  .skills-tile {
    background-color: transparentize($primary-bg-light, 0.95);
  }
}