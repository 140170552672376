@import 'themes/theme-dark';
@import 'themes/theme-light';

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.nav-links-container {
  display: flex;
  gap: 1.5vw;
}

.hover-expand {
  transition: transform 600ms ease-out;
}

.hover-expand:hover {
  transform: scale(1.1);
}

.hover-opacity {
  opacity: 80%;
  transition: opacity 600ms ease;
}

.hover-opacity:hover {
  opacity: 100%;
}

.hidden {
  visibility: hidden;
}

.section-content {
  max-width: 1440px;
  width: 70%;
  align-self: center;

  @media only screen and (max-width: 1280px), screen and  (min-width: 1440px) {
    width: 80%;
  }
}

a {
  display: flex;
  width: fit-content;
  cursor: pointer;
  transition: text-shadow 500ms;
  text-decoration: none;

  &:hover {
    p {

      &::after {
        transform: scaleX(1) translateY(0.5rem);
        transform-origin: left;
      }
    }
  }
}

.link-text {
  position: relative;
  width: fit-content;
  cursor: pointer;
  transition: text-shadow 0.5s;
  margin: 0;

  &::after {
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0;
    width: 100%;
    transform: scaleX(0) translateY(0.5rem);
    transform-origin: right;
    transition: transform 500ms;
    height: 3px;
    background-color: rgb(255, 255, 255, 0.64);
  }

  @media only screen and (max-width: 1280px) {
    text-decoration: underline;
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 5vh 0;

  @media only screen and (max-width: 1280px) {
    margin: 3vh 0;
  }
}

.section-title {
  padding-bottom: 5%;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
}

