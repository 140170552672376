//.language-icon {
//  font-size: 50px;
//  cursor: pointer;
//  margin-left: 1.25rem;
//}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 4rem;
  width: 100%;
  position: fixed;
  z-index: 10;

  font-size: 3rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  backdrop-filter: blur(100px);

  @media only screen and (max-width: 1280px) {
    padding: 1rem 0.5rem;
    font-size: 2rem;
    backdrop-filter: blur(100px);
  }
}

.nav-link {
  background-color: transparent;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border: 0;
}

.nav-link:hover {
  color: white;
}

.option-container {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 1.5vw;
  @media only screen and (max-width: 1280px) {
    gap: 1vw;
  }
}

.theme-container {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    font-size: 3rem;
  }
}

.language-container {
  visibility: hidden;
  width: 0;
  height: 0;
}

