
.project-header {
  font-size: 5rem;
  @media only screen and (max-width: 1280px) {
    text-align: center;
    font-size: 3rem;
  }
}

.project-subheader {
  font-size: 3rem;

  @media only screen and (max-width: 1280px) {
    text-align: center;
    font-size: 2rem;
  }
}

.project-link {
  margin-top: 2rem;
  font-size: 2rem;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-weight: bold;
}

.project-date {
  font-size: 3rem;

  @media only screen and (max-width: 1280px) {
    text-align: center;
    font-size: 1.75rem;
  }
}

.small-icons {
  font-size: 6rem;
  @media only screen and (max-width: 1280px) {
    font-size: 4rem;
  }
}

.icon-list {
  margin: 0 1.5vw;

  @media only screen and (max-width: 1280px) {
    margin: 0 2vw;
  }
}

.summary {
  font-size: 4rem;

  @media only screen and (max-width: 1280px) {
    text-align: center;
    font-size: 3rem;
    padding-top: 3rem;
  }
}

.description-text {
  font-size: 2.1rem;

  @media only screen and (max-width: 1280px) {
    text-align: center;
    font-size: 1.75rem;
    max-width: 100%;
  }
}

.project-text-container {
  display: flex;
  max-width: min(30vw, 700px);
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media only screen and (max-width: 1280px) {
    max-width: 75vw;
  }
}

.details {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  gap: 5rem;
}

.project-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;

  @media only screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 40px;
  }
}

.project-information-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 90px;
  text-align: left;

  @media only screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
}

.odd-info-container {
  flex-direction: row-reverse;

  @media only screen and (max-width: 1280px) {
    flex-direction: column;
  }
}

.even-info-container {
  text-align: left;

  @media only screen and (max-width: 1280px) {
    text-align: center;
  }
}

.odd {
  align-items: end;
  text-align: right;

  @media only screen and (max-width: 1280px) {
    text-align: center;
    align-items: center;
  }
}

.even {
  text-align: right;

  @media only screen and (max-width: 1280px) {
    text-align: center;
  }
}

.tech {
  display: flex;
  align-content: center;
  justify-content: center;
}


// Images
.project-image-container {
  display: grid;

  &:hover > .blur {
    filter: blur(2rem);
  }
}

.blur {
  position: relative;
  grid-area: 1 / 1;
  border-radius: 7px;
  width: 100%;
  max-width: 600px;
  object-fit: cover;
  object-position: center;
  filter: blur(3rem);
  transition: opacity 0.5s ease, filter 0.5s;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  @media only screen and (max-width: 1280px) {
    filter: blur(2rem);
  }
}

.project-image {
  grid-area: 1 / 1;
  position: relative;
  width: 100%;
  max-width: 600px;
  transition: opacity 0.7s;
  object-fit: cover;
  object-position: center;
  border-radius: 7px;

  @media only screen and (max-width: 1280px) {
    max-width: 600px;
  }
}




